import firebase from 'firebase/app';
import { SESSION_COLLECTION, SESSION_USERS_COLLECTION, SessionPublicData } from '@disco/data';
import { useCookies, useGameResources } from '@disco/hooks';
import { STEP_TYPES } from '@disco/steps';
import { UserDiscoContext, UserDiscoData } from '@disco/ui';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dimmer, Loader } from 'semantic-ui-react';
import { isSelectGroupType } from '@disco/utils';

interface PresenterProps {
  data: SessionPublicData;
}

export function Presenter({ data }: PresenterProps) {
  const { t } = useTranslation();

  const [userData, setUserData] = useState({});

  const { getSessionCookie } = useCookies();
  const { uid, token } = useContext<UserDiscoData>(UserDiscoContext);

  const [cookie, setCookie] = useState(getSessionCookie());
  const [currentStepType, setCurrentStepType] = useState('');

  const { resources, loading } = useGameResources(data.gameId, token);

  useEffect(() => {
    return firebase
      .firestore()
      .collection(SESSION_COLLECTION)
      .doc(data.sessionId)
      .collection(SESSION_USERS_COLLECTION)
      .doc(uid)
      .onSnapshot((doc) => {
        setUserData(doc.data());
      });
  }, [data.sessionId, uid]);

  useEffect(() => {
    const updateCookie = () => {
      setCookie(getSessionCookie());
    };
    document.addEventListener('cookie-updated', updateCookie, false);
    return () => document.removeEventListener('cookie-update', updateCookie, false);
  });

  useEffect(() => {
    const canDisplayCurrentStep =
      data && (!data?.requiredStep || (isSelectGroupType(data?.requiredStep?.stepType) && cookie.groupName));

    if (canDisplayCurrentStep) {
      setCurrentStepType(data.currentStep.type);
    } else {
      setCurrentStepType(data?.requiredStep?.stepType);
    }
  }, [cookie, data]);

  const CurrentComponent =
    STEP_TYPES[currentStepType] ||
    (() => (
      <Dimmer active inverted>
        <Loader>{t('session.preview.loading')}</Loader>
      </Dimmer>
    ));

  return !loading ? (
    <CurrentComponent data={{ ...data, userData, resources: { ...resources } }} uid={uid} />
  ) : (
    <Dimmer active inverted>
      <Loader>{t('session.preview.loading')}</Loader>
    </Dimmer>
  );
}

export default Presenter;
