import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';

import en from './locales/en.json';
import pl from './locales/pl.json';
import { plSteps, enSteps } from '@disco/steps';
import { enCommon, plCommon } from '@disco/ui';

i18n
  .use(intervalPlural)
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: { ...en, ...enCommon, ...enSteps },
      },
      pl: {
        translation: { ...pl, ...plCommon, ...plSteps },
      },
    },
    detection: {
      order: ['cookie'],
    },
    fallbackLng: 'en',
    debug: false,
  });

export default i18n;
