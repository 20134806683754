import { companyColor, FindSessionResp } from '@disco/data';
import { useCookies } from '@disco/hooks';
import { AsobiLogo, Card, Page } from '@disco/ui';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Message } from 'semantic-ui-react';
import styled from 'styled-components';
import { useApiCalls } from '../hooks/use-api-calls';

const Wrapper = styled.div`
  margin: 80px 0;
`;

export function SessionCode({ onAnonymousLogin }) {
  const { t } = useTranslation();

  const [sessionCode, setSessionCode] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [validationError, setValidationError] = useState({});
  const [joiningError, setJoiningError] = useState({});

  const { joinSession } = useApiCalls();
  const { setSessionCookie } = useCookies();

  const onSubmit = async () => {
    setSubmitting(true);
    if (!sessionCode) {
      setValidationError({
        content: t('session.noCodeError'),
        pointing: 'below',
      });
      setSubmitting(false);
      return;
    }
    if (!validateCode(sessionCode)) {
      setValidationError({
        content: t('session.validationError'),
        pointing: 'below',
      });
      setSubmitting(false);
      return;
    }
    const body = { code: sessionCode };
    const response = await joinSession(body);

    if (response.code === 200) {
      onAnonymousLogin();
      setSessionCookie(response.data as FindSessionResp);
    } else {
      setJoiningError({
        header: t('session.joiningErrorHeader'),
        content: t('session.joiningErrorContent'),
      });
    }
    setSubmitting(false);
  };

  const validateCode = (code) => {
    return code && /^[a-zA-Z0-9]+$/.test(code);
  };

  useEffect(() => {
    setValidationError(false);
    setJoiningError(false);
    setSubmitting(false);
  }, [sessionCode]);

  return (
    <Page logoAlwaysVisible>
      <Wrapper>
        <AsobiLogo />
        <Card title={t('session.title')} color={companyColor.violet}>
          <Form error={!!Object.keys(joiningError).length} size="large" onSubmit={onSubmit}>
            <Form.Input
              placeholder={t('session.sessionCode')}
              onChange={({ target: { value } }) => setSessionCode(value.toUpperCase())}
              value={sessionCode}
              error={Object.keys(validationError).length ? validationError : false}
            />
            <Button
              loading={submitting}
              style={{ backgroundColor: companyColor.violet, color: 'white' }}
              fluid
              size="large"
            >
              {t('session.submit')}
            </Button>
            <Message error header={joiningError['header']} content={joiningError['content']} />
          </Form>
        </Card>
      </Wrapper>
    </Page>
  );
}
