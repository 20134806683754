import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Text from '../text/text';

import logo from './logo.png';

const LogoWrapper = styled.div<{ alwaysVisible: boolean; christmas: boolean; santa: boolean }>`
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  right: 0;
  padding: 8px 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
  background: ${(props) => (props.christmas ? 'rgba(255,255,255, 0.6)' : '')};
  font-size: 24px;

  b {
    font-weight: 800;
  }
  ${(props) =>
    props.santa &&
    `
    p {
      font-family: Aleo;
      color: white;
      text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.8), 2px 2px 10px #000000e3;
    }
  `}

  @media screen and (max-width: 1000px) {
    display: ${(props) => !props.alwaysVisible && 'none'};
  }
`;

const LogoImage = styled.img`
  width: auto;
  height: 120px;
`;

export const LogoElement = () => <LogoImage src={logo} alt={logo} />;

export const Logo = ({ sessionCode = '', showLogoBanner = true, alwaysVisible = false, style = null }) => {
  const { t } = useTranslation();
  const bannerText = sessionCode ? String(t('logo', { domain: process.env.NX_APP_DOMAIN, sessionCode })) : '';
  const christmasStyle = style === 'christmas';
  const santaStyle = style === 'santa';

  return (
    showLogoBanner && (
      <LogoWrapper santa={santaStyle} christmas={christmasStyle} alwaysVisible={alwaysVisible}>
        {!alwaysVisible && (
          <div style={{ visibility: 'hidden' }}>
            <LogoImage src={logo} alt={logo} />
          </div>
        )}
        <Text dangerouslySetInnerHTML={{ __html: bannerText }} textAlign="center"></Text>
        {christmasStyle || santaStyle ? (
          <div style={{ visibility: 'hidden' }}>
            <LogoImage src={logo} alt={logo} />
          </div>
        ) : (
          <LogoImage src={logo} alt={logo} />
        )}
      </LogoWrapper>
    )
  );
};
