import { useApi } from 'libs/steps/src/lib/generic/hooks/use-api';
import { useCallback } from 'react';

export const useApiCalls = () => {
  const callApi = useApi();

  const joinSession = useCallback(
    async (body) => {
      return await callApi('/session/join', { method: 'POST', body });
    },
    [callApi]
  );

  return {
    joinSession,
  };
};
