import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ColorPair, colorConfig } from '../../config/colors';
import { Boat } from './Boat';
import { Button } from './Button';

const Text = styled.div`
  margin-top: 3px;
`;

const Box = styled.div`
  display: flex;
  gap: 12px;
  font-size: 1.2em;
`;

const BoxSpace = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const PointsBox = styled.div`
  border: 1px solid #ffffff;
  position: absolute;
  right: 0;
  border-radius: 8px;
  padding: 16px;
  color: #114a94;
  background-color: #a5d6ee;
  width: auto;
`;

export const StrategyBox = ({
  data,
  title,
  text,
  color,
  selected,
  onClick,
  addonImage,
  points = null,
}: {
  title: string;
  color: ColorPair;
  data?: any;
  text?: string;
  selected?: boolean;
  onClick?: () => void;
  addonImage?: any;
  points?: any;
}) => {
  return (
    <Button onClick={onClick} selected={selected} bgColor={color.light} fontColor={color.dark}>
      <BoxSpace>
        <Box>
          <Boat color={'white'} />
          <Text>
            {title}
            {text}
          </Text>
          {points !== null && <PointsBox>{points}</PointsBox>}
        </Box>
        {addonImage}
      </BoxSpace>
    </Button>
  );
};

export const RedStrategyBox = ({ ...props }) => {
  const { t } = useTranslation();

  return <StrategyBox {...props} title={t('fishery.strategyRedTitle')} color={colorConfig.red} />;
};

export const GreenStrategyBox = ({ ...props }) => {
  const { t } = useTranslation();

  return <StrategyBox {...props} title={t('fishery.strategyGreenTitle')} color={colorConfig.green} />;
};

export const BlueStrategyBox = ({ ...props }) => {
  const { t } = useTranslation();

  return <StrategyBox {...props} title={t('fishery.strategyBlueTitle')} color={colorConfig.blue} />;
};

export const YellowStrategyBox = ({ ...props }) => {
  const { t } = useTranslation();

  return <StrategyBox {...props} title={t('fishery.strategyYellowTitle')} color={colorConfig.yellow} />;
};

export const OrangeStrategyBox = ({ ...props }) => {
  const { t } = useTranslation();

  return <StrategyBox {...props} title={t('fishery.strategyOrangeTitle')} color={colorConfig.orange} />;
};

export const NoneStrategyBox = ({ ...props }) => {
  const { t } = useTranslation();

  return <StrategyBox title={t('fishery.strategyNoneTitle')} color={colorConfig.none} />;
};
