import React, { useEffect, useState } from 'react';
import { PrivateRoute, UserDiscoContext } from '@disco/ui';
import { useCookies, useFirebase } from '@disco/hooks';
import { SessionCode } from './components/session-code';
import { Dimmer, Loader } from 'semantic-ui-react';
import firebase from 'firebase/app';
import { Route, Switch } from 'react-router-dom';
import SessionPreview from './components/session-preview';
import { useHistory } from 'react-router-dom';

const prodConfig = {
  apiKey: 'AIzaSyAsybcHC6HvWdQN2f7g332D43quWFkdGVM',
  authDomain: 'disco-ament.firebaseapp.com',
  projectId: 'disco-ament',
  storageBucket: 'disco-ament.appspot.com',
  messagingSenderId: '312472167489',
  appId: '1:312472167489:web:dc6e7cdf0b82f20ccbd2c0',
  measurementId: 'G-D3M21Y9LJK',
};

const testConfig = {
  apiKey: 'AIzaSyA8SfSN9CDMce6UEfsFW1YM03hlwTfWJCs',
  authDomain: 'asobi-7cc09.firebaseapp.com',
  projectId: 'asobi-7cc09',
  storageBucket: 'asobi-7cc09.appspot.com',
  messagingSenderId: '748579902292',
  appId: '1:748579902292:web:6b0aec161c459634d76ec1',
  measurementId: 'G-L3PV3HH7DQ',
};

export function App() {
  const config = prodConfig;

  const { loading } = useFirebase(config);
  const [authenticated, setAuthenticated] = useState(false);
  const [error, setError] = useState<string | boolean>(false);
  const [userLoaded, setUserLoaded] = useState(false);
  const [userState, setUserState] = useState({});
  const history = useHistory();
  const { getSessionCookie } = useCookies();

  useEffect(() => {
    if (!loading) {
      firebase.auth().onAuthStateChanged(async (user) => {
        if (user) {
          setAuthenticated(!!user?.uid);
          setError(false);
          setUserState({ uid: await user?.uid, token: await user?.getIdToken(true) });
          setInterval(async () => {
            setUserState({ uid: await user?.uid, token: await user?.getIdToken(true) });
          }, 5 * 60 * 1000);
        } else {
          history.push('/');
        }
        setUserLoaded(true);
      });
    }
  }, [getSessionCookie, history, loading]);

  const onAnonymousLogin = async () => {
    firebase
      .auth()
      .signInAnonymously()
      .then(() => {
        setAuthenticated(true);
        history.push('/training');
      })
      .catch((err) => console.error(err));
  };

  return userLoaded ? (
    <Switch>
      <Route exact path="/">
        <SessionCode onAnonymousLogin={onAnonymousLogin} />
      </Route>
      <UserDiscoContext.Provider value={userState}>
        <PrivateRoute allow={authenticated} exact path="/training" component={SessionPreview} redirect="/" />
      </UserDiscoContext.Provider>
    </Switch>
  ) : (
    <Dimmer active inverted>
      <Loader />
    </Dimmer>
  );
}

export default App;
