import React, { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import Presenter from './presenter';
import { SESSION_PUBLIC_COLLECTION, SessionPublicData } from '@disco/data';
import { useCookies } from '@disco/hooks';
import { Dimmer, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';

export function SessionPreview() {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState<SessionPublicData>();

  const { getSessionCookie } = useCookies();

  useEffect(() => {
    const { sessionCode } = getSessionCookie();

    return firebase
      .firestore()
      .collection(SESSION_PUBLIC_COLLECTION)
      .doc(sessionCode)
      .onSnapshot((doc) => {
        setData(doc.data());
      });
  }, [getSessionCookie]);

  useEffect(() => {
    if (data?.language && data?.language !== i18n.language) {
      i18n.changeLanguage(data.language);
    }
  }, [data?.language]);

  return data ? (
    <Presenter data={data} />
  ) : (
    <Dimmer active inverted>
      <Loader>{t('session.preview.waiting')}</Loader>
    </Dimmer>
  );
}

export default SessionPreview;
