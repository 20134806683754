export const getScoreTable = ({ userCount = 10, stocking = 0 }) => {
  const ranges = getRanges(Math.max(userCount, 10));

  return ranges.map((range, index) => {
    const bottom = range.bottom;
    const top = range.top;
    const isZeroRange = bottom === 0 && bottom === 0;
    return {
      range: isZeroRange ? '0' : `${bottom}${bottom === top ? '' : range.top ? `-${top}` : '+'}`,
      green: greenScoreTable[stocking][ranges.length - 1 - index],
      red: redScoreTable[stocking][ranges.length - 1 - index],
    };
  });
};

export const getRangeIndex = (userCount, redCount) => {
  const ranges = getRanges(Math.max(userCount, 10));
  const index = ranges.findIndex((range) => {
    // For ranges with no top (highest range), only check bottom
    if (!range.top) {
      return redCount >= range.bottom;
    }
    // For all other ranges, check if redCount falls within range inclusive
    return redCount >= range.bottom && redCount <= range.top;
  });
  // reverse index
  return ranges.length - 1 - index;
};

export const getRanges = (n: number) => {
  // Ensure minimum number of players is 10
  n = Math.max(10, Math.floor(n));

  return [
    { bottom: 0, top: 0 }, // index 0
    { bottom: 1, top: Math.floor(0.16 * n) }, // index 1
    { bottom: Math.floor(0.16 * n), top: Math.floor(0.31 * n) }, // index 2
    { bottom: Math.floor(0.31 * n), top: Math.floor(0.46 * n) }, // index 3
    { bottom: Math.floor(0.46 * n), top: Math.floor(0.56 * n) }, // index 4
    { bottom: Math.floor(0.56 * n), top: Math.floor(0.71 * n) }, // index 5
    { bottom: Math.floor(0.71 * n) },
  ]
    .map((range, index, arr) => {
      // Adjust bottom to ensure no overlapping ranges
      if (index > 1) {
        range.bottom = Math.max(range.bottom, arr[index - 1].top + 1);
      }
      return range;
    })
    .reverse();
};

export const redScoreTable = [
  [0, 102, 92, 82, 72, 62, 52],
  [0, 104, 94, 84, 74, 64, 54],
  [0, 110, 100, 90, 80, 70, 60],
  [0, 120, 110, 100, 90, 80, 70],
  [0, 150, 140, 130, 120, 110, 100],
  [0, 190, 180, 170, 160, 150, 140],
  [0, 250, 240, 230, 220, 210, 200],
  [0, 310, 300, 290, 280, 270, 260],
  [0, 350, 340, 330, 320, 310, 300],
  [0, 380, 370, 360, 350, 340, 330],
  [0, 390, 380, 370, 360, 350, 340],
  [0, 394, 384, 374, 364, 354, 344],
  [0, 398, 388, 378, 368, 358, 348],
];

export const greenScoreTable = [
  [26, 21, 16, 11, 6, 1, 0],
  [27, 22, 17, 12, 7, 2, 0],
  [30, 25, 20, 15, 10, 5, 0],
  [35, 30, 25, 20, 15, 10, 0],
  [50, 45, 40, 35, 30, 25, 0],
  [70, 65, 60, 55, 50, 45, 0],
  [100, 95, 90, 85, 80, 75, 0],
  [130, 125, 120, 115, 110, 105, 0],
  [150, 145, 140, 135, 130, 125, 0],
  [165, 160, 155, 150, 145, 140, 0],
  [170, 165, 160, 155, 150, 145, 0],
  [173, 168, 163, 158, 153, 148, 0],
  [174, 169, 164, 159, 154, 149, 0],
];
